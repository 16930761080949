import kubernetesBrandImageSrc from './images/kubernetes-brands.svg';
import { toggleSidebar } from './js/portal/sidebar';
import { CONF } from './js/portal/environment';
import { linkWithoutSearch } from './js/portal/main';

function MenuItem({
  level,
  text,
  hash,
  externalLink,
  linkId,
  itemId,
  icon,
  iconSrc,
  iconAlt,
  visible,
  deprecated,
  announce,
  children,
  featureFlag,
  microServiceRoute,
}) {
  if (!level) level = 1;

  let iconElement;
  if (icon) {
    iconElement = <i class={`sidebar-item-icon fas fa-fw fa-${icon}`} />;
  } else if (iconSrc) {
    iconElement = <img src={iconSrc} class="sidebar-item-icon" alt={iconAlt ?? ''} />;
  }

  const deprecationBadge = deprecated && <span class="sidebar-badge badge bg-secondary">Deprecated</span>;
  const newBadge = announce && <span class="sidebar-badge badge bg-secondary">NEW</span>;

  return (
    <li
      id={itemId}
      data-feature-flag={featureFlag}
      class={`sidebar-item sidebar-item-level${level}`}
      style={visible ? '' : { display: 'none' }}>
      {hash && (
        <a href={linkWithoutSearch(hash)} data-hash={hash}>
          {[iconElement, text, deprecationBadge, newBadge]}
        </a>
      )}
      {externalLink && (
        <a id={linkId} href={externalLink} target="_blank" rel="noopener noreferrer">
          {text}
          <i
            class="fas fa-external-link-alt"
            style="margin-left: 0.5em; font-size: smaller; align-self: center; margin-top: -0.1em"
          />
        </a>
      )}
      {microServiceRoute && <a href={microServiceRoute}>{[iconElement, text, deprecationBadge, newBadge]}</a>}
      {children && (
        <>
          <a
            class="sidebar-menu-title collapsed"
            role="button"
            data-bs-toggle="collapse"
            data-bs-target={`#${itemId}_menu`}
            aria-expanded="false"
            aria-controls={`${itemId}_menu`}>
            {[iconElement, text, deprecationBadge, newBadge]}
          </a>
          <ul id={`${itemId}_menu`} class="sidebar-menu collapse">
            {
              /* Move child menu items to the next level */
              (Array.isArray(children) ? children : [children]).map(incrementChildItemLevel)
            }
          </ul>
        </>
      )}
    </li>
  );
}

/**
 * Adjust the hierarchy level on a child node.
 *
 * With our current JSX implementation, the child nodes are immediately rendered as DOM elements without knowing
 * about the parent context. For the sidebar menu, the parent "knows" that the children will have to be moved one
 * level down, but at that point they are already rendered as `<li>` elements, so we have to recursively modify the
 * `sidebar-item-levelX` class on each child item.
 *
 * @param childNode {HTMLElement} the child node to modify
 * @return the modified child node
 */
function incrementChildItemLevel(childNode) {
  if (childNode instanceof HTMLElement) {
    childNode.classList.replace('sidebar-item-level2', 'sidebar-item-level3');
    childNode.classList.replace('sidebar-item-level1', 'sidebar-item-level2');
    childNode.childNodes.forEach(incrementChildItemLevel);
  }
  return childNode;
}

export default function Sidebar() {
  const sidebar = (
    <div className="sidebar flex-column navbar-collapse d-md-block collapse" role="navigation" id="sidebar">
      <ul className="sidebar-menu">
        {/* Dashboard Menu */}
        <MenuItem visible text="Dashboard" hash="#dashboard" itemId="sidebarItemDashboard" icon="tachometer-alt" />

        {/* Orders Menu */}
        <MenuItem text="Orders" itemId="sidebarItemOrders" icon="cube">
          <MenuItem text="Order Details" hash="#orderdetails" itemId="sidebarItemOrderDetails" />
          <MenuItem text="List Orders" hash="#orders" itemId="sidebarItemListOrders" />
        </MenuItem>

        {/* Accounts Menu */}
        <MenuItem text="AWS Accounts" itemId="sidebarItemAccounts" icon="cloud">
          <MenuItem text="AWS Account Details" hash="#accountdetails" itemId="sidebarItemAccountDetails" />
          <MenuItem text="List AWS Accounts" hash="#listaccounts" itemId="sidebarItemListAccounts" />
          <MenuItem
            text="Order AWS Account"
            externalLink={CONF.awsLoginDetails.myBMWItUrl}
            linkId="sidebarItemCreateAccountLink"
            itemId="sidebarItemCreateAccount"
          />
          <MenuItem text="Close AWS Account" hash="#closeaccount" itemId="sidebarItemCloseAccount" />
          <MenuItem text="Request ICP Exception" hash="#icpaccountexception" itemId="sidebarItemIcpAccountException" />
        </MenuItem>

        {/* IDC Maganement */}
        <MenuItem text="  IAM Center" itemId="sidebarItemIamCenter" featureFlag="idc.enabled" icon="sign-in">
          <MenuItem
            text="Classic Login Opt-Out"
            microServiceRoute="/idc/classic-login-opt-out"
            itemId="sidebarItemClassicLoginOptOut"
          />
          <MenuItem
            text="List Users"
            microServiceRoute="/idc/list-users"
            featureFlag="idc.list_users"
            itemId="sidebarItemIdcListUsers"
          />
          <MenuItem
            text="Order Custom Role"
            microServiceRoute="/idc/order-custom-role"
            featureFlag="idc.custom_role_account"
            itemId="sidebarItemIdcOrderCustomRole"
          />
          <MenuItem
            text="List Custom Roles"
            microServiceRoute="/idc/list-custom-roles"
            featureFlag="idc.custom_role_account"
            itemId="sidebarItemIdcListCustomRoles"
          />
          <MenuItem
            text="Order Account Area Custom Role"
            microServiceRoute="/idc/order-custom-role-account-area"
            featureFlag="idc.custom_role_account_area"
            itemId="sidebarItemIdcOrderCustomRoleAccountArea"
          />
          <MenuItem
            text="List Account Area Custom Roles"
            microServiceRoute="/idc/list-custom-roles-account-area"
            featureFlag="idc.custom_role_account_area"
            itemId="sidebarItemIdcListCustomRolesAccountArea"
          />
        </MenuItem>

        {/* Access Management Menu */}
        <MenuItem text="Access Management / IAM" itemId="sidebarItemAccessManagement" icon="users">
          <MenuItem text="AD Users" itemId="sidebarItemADUserManagement">
            <MenuItem text="Enable AD User" hash="#enableaduser" itemId="sidebarItemEnableAdUser" />
            <MenuItem text="Update AD User" hash="#updateaduser" itemId="sidebarItemUpdateAdUser" />
            <MenuItem text="Assign AD User" hash="#addaduser" itemId="sidebarItemAddAdUser" />
            <MenuItem text="Remove AD User" hash="#removeaduser" itemId="sidebarItemRemoveAdUser" />
          </MenuItem>
          <MenuItem text="IAM Service Users" itemId="sidebarItemServiceUsers">
            <MenuItem text="Create IAM Service User" hash="#createserviceuser" itemId="sidebarItemCreateServiceUser" />
            <MenuItem text="Delete IAM Service User" hash="#deleteserviceuser" itemId="sidebarItemDeleteServiceUser" />
          </MenuItem>
          <MenuItem text="List and Confirm Users" hash="#confirmusers" itemId="sidebarItemConfirmUsers" />
        </MenuItem>

        {/* Networks Menu */}
        <MenuItem text="Networks / VPCs" itemId="sidebarItemNetworks" icon="random">
          <MenuItem text="Network Usage" hash="#networkusage" itemId="sidebarItemNetworkUsage" />
          <MenuItem text="Network Details" hash="#networkdetails" itemId="sidebarItemNetworkDetails" />
          <MenuItem text="List Direct Connect Gateways" hash="#listdxgw" itemId="sidebarItemListDXGWs" />
          <MenuItem text="List VPCs" hash="#listvpc" itemId="sidebarItemListVPCs" />
          <MenuItem text="Compare VPCs" hash="#comparevpc" itemId="sidebarItemCompareVPCs" />
          <MenuItem text="Create VPC" hash="#createnetwork" itemId="sidebarItemCreateVPC" />
          <MenuItem text="Destroy VPC" hash="#destroyvpc" itemId="sidebarItemDestroyVPC" />
          <MenuItem text="Create VPC Peering" hash="#createpeering" itemId="sidebarItemCreateVPCPeering" />
          <MenuItem text="Destroy VPC Peering" hash="#destroypeering" itemId="sidebarItemDestroyVPCPeering" />
        </MenuItem>

        {/* FQDN Management */}
        <MenuItem text="Internet Egress" itemId="sidebarItemFqdnManagement" featureFlag="fqdn.enabled" icon="globe">
          <MenuItem
            text="Create Internet Proxy Report"
            microServiceRoute="/fqdn/create-fqdn-proxy-report"
            itemId="sidebarItemCreateFQDNProxyReport"
          />
          <MenuItem
            text="List Internet Proxy Reports"
            microServiceRoute="/fqdn/list-fqdn-proxy-reports"
            itemId="sidebarItemListFQDNProxyReports"
          />
          <MenuItem
            text="Weekly Proxy Reports"
            microServiceRoute="/fqdn/list-fqdn-weekly-proxy-reports"
            itemId="sidebarItemListFQDNWeeklyProxyReports"
          />
          <MenuItem
            text="Create Internet Firewall Blocking Report"
            microServiceRoute="/fqdn/create-fqdn-blocking-report"
            itemId="sidebarItemCreateFQDNProxyReport"
            featureFlag="fqdn.blocklist"
          />
          <MenuItem
            text="Internet Firewall Blocking Report"
            microServiceRoute="/fqdn/list-fqdn-blocking-reports"
            itemId="sidebarItemListFQDNProxyReports"
            featureFlag="fqdn.blocklist"
          />
          <MenuItem
            text="Request Internet Firewall Allowlist"
            microServiceRoute="/fqdn/request-fqdn-allowlist"
            itemId="sidebarItemRequestFQDNAllowlist"
            featureFlag="fqdn.allowlist"
          />
          <MenuItem
            text="Manage Internet Firewall Allowlist"
            microServiceRoute="/fqdn/manage-fqdn-allowlist"
            itemId="sidebarItemManageFQDNAllowlist"
            featureFlag="fqdn.allowlist"
          />
          <MenuItem
            text="Internet Firewall Recertification"
            microServiceRoute="/fqdn/fqdn-recertification"
            itemId="sidebarItemFQDNRecertification"
            featureFlag="fqdn.recertification"
          />
        </MenuItem>

        {/* Domains Menu */}
        <MenuItem text="DNS / Hosted Zones" itemId="sidebarItemDNS" icon="wrench">
          <MenuItem text="Hosted Zone Details" hash="#hostedzonedetails" itemId="sidebarItemHostedZonesDetails" />
          <MenuItem text="List Hosted Zones" hash="#listhostedzone" itemId="sidebarItemListHostedZones" />
          {/* <MenuItem text="Check Hosted Zone" hash="#checkhostedzone" itemId="sidebarItemCheckHostedZones" /> */}
          <MenuItem text="Create Hosted Zone" hash="#createhostedzone" itemId="sidebarItemCreateHostedZones" />
          <MenuItem text="Destroy Hosted Zone" hash="#destroyhostedzone" itemId="sidebarItemDestroyHostedZones" />
          <MenuItem text="Associate Hosted Zone" hash="#associatehostedzone" itemId="sidebarItemAssociateHostedZones" />
          <MenuItem text="Onboard Hosted Zone" hash="#onboardhostedzone" itemId="sidebarItemOnboardHostedZones" />
        </MenuItem>

        {/* 4wheels Menu */}
        <MenuItem
          text="4wheels Guided"
          itemId="sidebarItem4Wheels"
          iconSrc={kubernetesBrandImageSrc}
          iconAlt="Kubernetes Logo"
          featureFlag="4wheels.enabled">
          <MenuItem text="Cluster Details" hash="#4wheelsdetails" itemId="sidebarItem4WheelsDetails" />
          <MenuItem text="List Clusters" hash="#list4wheels" itemId="sidebarItemList4Wheels" />
          <MenuItem
            text="Create Cluster"
            hash="#create4wheels"
            itemId="sidebarItemCreate4Wheels"
            featureFlag="!4wheels.read_only"
          />
        </MenuItem>

        {/* Marketplace Menu */}
        <MenuItem
          text="Marketplace"
          hash="#marketplacepurchases"
          itemId="sidebarItemMarketplacePurchases"
          icon="shopping-cart"
        />

        {/* SIEM Menu */}
        <MenuItem text="SIEM" itemId="sidebarItemSiemMenu" icon="clipboard-check">
          <MenuItem text="Onboarding Request" hash="#siemonboarding" itemId="sidebarItemSiemOnboarding" />
        </MenuItem>

        {/* Trainings Menu */}
        <MenuItem text="Trainings" itemId="sidebarItemTrainingsMenu" icon="book">
          <MenuItem text="List Training Accounts" hash="#listTrainingAccounts" itemId="sidebarItemTrainingAccounts" />
          <MenuItem text="List Trainings" hash="#listTrainings" itemId="sidebarItemTrainingsList" />
          <MenuItem text="Plan Trainings" hash="#planTraining" itemId="sidebarItemTrainingsPlan" />
        </MenuItem>

        {/* Support Menu */}
        <MenuItem text="Support" itemId="sidebarItemSupportMenu" icon="question-circle">
          <MenuItem
            text="Documentation"
            externalLink="https://developer.bmwgroup.net/docs/public-cloud-platform-aws/"
            linkId="sidebarItemSupportDocumentationLink"
            itemId="sidebarItemSupportDocumentation"
          />
          <MenuItem
            text="Ask a Question"
            externalLink="https://atc.bmwgroup.net/confluence/display/DEVOPSPF/questions/all"
            linkId="sidebarItemSupportQuestionLink"
            itemId="sidebarItemSupportQuestion"
          />
        </MenuItem>

        {/* ITSM Menu */}
        <MenuItem hidden text="ITSM Event Bridge (deprecated)" hash="#itsm" itemId="sidebarItemITSM" icon="bell" />

        {/* Statistics Menu */}
        <MenuItem text="Statistics" itemId="sidebarItemStatistics" icon="chart-line">
          <MenuItem visible text="Networks" hash="#networkstatistics" itemId="sidebarItemStatisticsNetwork" />
          <MenuItem visible text="Accounts" hash="#accountstatistics" itemId="sidebarItemStatisticsAccount" />
          <MenuItem visible text="Orders" hash="#orderstatistics" itemId="sidebarItemStatisticsOrder" />
          <MenuItem visible text="Series" hash="#seriesstatistics" itemId="sidebarItemStatisticsSeries" />
        </MenuItem>

        {/* Admins Menu */}
        <MenuItem text="Administration" itemId="sidebarItemAdminMenu" icon="lock">
          <MenuItem text="Portal" itemId="sidebarItemAdminPortal">
            <MenuItem text="List Settings" hash="#listsettings" itemId="sidebarItemAdminListSettings" />
            <MenuItem text="Manage Banners" hash="#managebanners" itemId="sidebarItemAdminManageBanners" />
          </MenuItem>
          <MenuItem text="List API Keys" hash="#listapikeys" itemId="sidebarItemAdminListApiKeys" />
          <MenuItem text="Feature Flags" hash="#featureflags" itemId="sidebarItemAdminFeatureFlagsPage" />
          <MenuItem text="Access Management" itemId="sidebarItemAdminUsers">
            <MenuItem text="List Identity Providers" hash="#listidps" itemId="sidebarItemIdPs" />
            <MenuItem
              text="List User Permissions"
              hash="#listuserpermissions"
              itemId="sidebarItemAdminListUserPermissions"
            />
            <MenuItem
              text="List Invalid Principals"
              hash="#listinvalidprincipals"
              itemId="sidebarItemAdminListInvalidPrincipals"
            />
          </MenuItem>
          <MenuItem text="Organizations" itemId="sidebarItemAdminOrganizations">
            <MenuItem text="List Accounts" hash="#adminlistaccounts" itemId="sidebarItemAdminListAccounts" />
            <MenuItem text="Move Accounts" hash="#adminmoveaccount" itemId="sidebarItemAdminMoveAccount" />
          </MenuItem>
          <MenuItem text="SIEM Create Key" hash="#siemcreatekey" itemId="sidebarItemSiemCreateKey" />
          <MenuItem
            text="4Wheels Guided Cluster Options"
            hash="#manage4wheelsclusteroptions"
            itemId="sidebarItemAdmin4Wheels"
          />
        </MenuItem>
      </ul>

      <div id="sidebar-close-button" class="sidebar-close d-none d-md-block">
        <button type="button" class="btn btn-close-sidebar" title="Show/Hide Sidebar (alt+s)" onclick={toggleSidebar}>
          <i id="sidebar-close-button-icon" class="fas fa-chevron-left" />
        </button>
      </div>
    </div>
  );

  // Automatically collapse unrelated submenus when a submenu is expanded
  $(sidebar).on('show.bs.collapse', 'ul.sidebar-menu.collapse', function (event) {
    const item = $(this).parent();
    $(sidebar)
      .find('ul.sidebar-menu.collapse:not(:has(#' + item.attr('id') + '))')
      .collapse('hide');
    event.stopPropagation(); // don't bubble up to containing submenus
  });

  return sidebar;
}
